<div class="text-sm font-semibold read-only-header p-2 m-0 flex  fixed-header mb-4">
    <span class="pl-[8px] pr-[24px]">
      <app-svg-icon
        class="info-icon"
        icon="info"
        color="#e5b611"
        padding="8px"
      ></app-svg-icon>
    </span>
    <span>
    You don't have permission to edit this plan since it is owned by another user. Only the plan owner is allowed to make modifications
    </span>
  </div>
