<button
  class="edit-modify flex items-center justify-center"
  pTooltip="Modify"
  tooltipPosition="top"
  (click)="buttonClickEvent()"
  [disabled]="disabled"
>
  <img
    src="/assets/images/icon-edit-black.svg"
    [ngStyle]="{
      width: iconSize ? iconSize : 'auto'
    }"
    alt="Edit"
  />
</button>
