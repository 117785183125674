import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, Params } from "@angular/router";
import { CatExpertRouterLink } from "../../app-routing-enum";
import { HttpService } from "../../services/http.service";
import { StateService } from "../../services/state.service";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
 
@Component({
  selector: "app-pho-sidebar",
  templateUrl: "./pho-sidebar.component.html",
  styleUrls: ["./pho-sidebar.component.scss"],
})
export class PhoSidebarComponent implements OnInit, OnChanges {
  clusteringMode: any = "";
  @Input() experimentDetails: any = [];
  @Input() planDetails: any = {};
  @Input() steps: any = [];
  @Input() spaceSteps: any = [];
  @Input() spaceDetails: any = {};
  @Input() editAccessType: boolean = false;
  @Input() isCollapse: boolean = false;
  @Output() actionCollapseSidebar = new EventEmitter<any>();
  @Input() phoSecondarySteps: any = [];
  @Input() phoDetails: any = {};
 
  sidebarCollapse: boolean = false;
  levelInfo: any = {};
  formGoback: any = {};
  level: string = "";
  expId!: string;
  experimentData: any = {
    name: "",
    time_period: "",
    country: "",
    year: "",
  };
  expName!: string | null;
  expTypes = {
    eda: "data exploration",
    clustering: "clustering",
    assortment: "assortment",
    planogram: "planogram",
  };
  allState: any[] = [];
  selectedStep!: any;
  sidebarStates: any = [];
  notification: string = "";
  statesSubscription: Subscription;
  expType: any = sessionStorage.getItem("expType") || "";
  domainType: string = "";
  [key: string]: any; // Index signature
  lockAdded: boolean = false;
  exprimentName: string = "";
  routeSubscriptions: any;
  routerParams: any = {};
 
  constructor(
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService
  ) {
    this.stateService.callSetLevel.subscribe((value: any) => {
      if (value?.length) {
        this.setLevel(value);
      }
    });
    // Function for trigger the states api call when changes in sku funnel and nesting
    this.statesSubscription = this.stateService
      .getStatesCall()
      .subscribe((apiFlag) => {
        if (apiFlag) {
          this.clusteringMode = sessionStorage.getItem("clusteringMode");
          this.getSidebarStatesCall();
        }
      });
 
    this.experimentData = {};
 
    this.route.params.subscribe((params: Params) => {
      let refId = sessionStorage.getItem("refId") || "";
      this.expId = refId ? sessionStorage.getItem("expId") : params.id;
      sessionStorage.setItem("expId", this.expId);
 
      let routeUrl = this.router.url.split("/");
      let lastParam = routeUrl[routeUrl.length - 1]?.split("?");
      this.level = lastParam[0]
        ? lastParam[0] === "summary"
          ? "voting"
          : lastParam[0]
        : lastParam[0];
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          let routeUrl = val.url.split("/");
          // this.level = routeUrl[routeUrl.length - 1];
          let lastParam = routeUrl[routeUrl.length - 1]?.split("?");
          this.level = lastParam[0];
        }
      });
      this.getSidebarStatesCall();
    });
    // syncing assortment steps lock value
    this.stateService.getAssortmentLock.subscribe((newValue: any) => {
      const lockAssortmentSteps = JSON.parse(
        sessionStorage.getItem("lockAssortment")!
      );
      if (lockAssortmentSteps) {
        this.lockAssortment = lockAssortmentSteps[this.expId!];
      }
    });
  }
 
  ngOnInit(): void {
    // Code Getting router queryParams
    this.routeSubscriptions = this.route.queryParams.subscribe(
      (params: Params) => {
        this.routerParams = params;
      }
    );

    if(!this.expType) {
      this.stateService.getExperimentDetailsByExpId().subscribe((val: any) => {
        sessionStorage.setItem("expType", val.experiment_type);
        this.expType = val.experiment_type;
      });
    }
    
    this.domainType = this.stateService.checkAppConfig();
    if (sessionStorage.getItem("expType") === "pho") {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.getSidebarStatesCall();
        });
    }
 
    this.clusteringMode = sessionStorage.getItem("clusteringMode");

    if (this.isCollapse) {
      this.checkSidebarActive();
    }
  }
 
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.experimentDetails = changes?.experimentDetails?.currentValue;
 
      this.experimentData = {
        name: this.experimentDetails?.expName,
        country: this.experimentDetails?.expCountry,
        year: this.experimentDetails?.period?.split("-")[0],
        time_period: this.experimentDetails?.period?.split("-")[1],
        key_accound: this.experimentDetails?.keyAccount,
        region: this.experimentDetails?.region,
      };
      if (this.experimentDetails) {
        let expNameSplit = this.experimentData?.name?.split(/[ _-]/);
        if (expNameSplit !== undefined && expNameSplit.length >= 2) {
          this.exprimentName = expNameSplit[1][0]
            ? `${expNameSplit[0][0]}${expNameSplit[1][0]}`
            : `${expNameSplit[0][0]}`;
        } else {
          this.exprimentName = expNameSplit ? expNameSplit[0][0] : "";
        }
      }
    }
  }
 
  setLevel(value = {}) {
    let process: any = [];
    try {
      process = value || JSON.parse(localStorage.process);
      const filteredSecondLevel = process.filter(
        (value: { order: any; state_id: any }) =>
          (value.order !== 1 || value.state_id === "generate-eda-data") &&
          value.order !== 2 &&
          value.order !== 3
      );
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].class = "";
        filteredSecondLevel.forEach((item: any) => {
          if (item.order === this.steps[i].order) {
            if (item.order === 6 || item.order === 7 || item.order === 8) {
              this.steps[i].class = item.status;
            } else {
              if (item.state_id === "clustering") {
                this.steps[i].class = item.status;
              }
              if (
                this.clusteringMode === "use_without_store_data" &&
                item.order === 5
              ) {
                this.steps[i].class = `${item.status} clusteringMode`;
              }
            }
            this.steps[i].status = item.status;
          }
        });
        if (this.steps[i].slug === this.level) {
          this.steps[i].class = this.steps[i].class + " eda-active";
          this.levelInfo = this.steps[i];
        }
      }
    } catch (e) {}
 
    if (this.levelInfo.order === 1) {
      this.formGoback = {
        url: `/${CatExpertRouterLink.Studio}`,
        text: `Back to Experiments`,
      };
    } else if (this.levelInfo.order - 1) {
      const previousLevel = this.steps[this.levelInfo.order - 5];
      this.formGoback = {
        url: `/${CatExpertRouterLink.Studio}`,
        text: `Back to Experiments`,
      };
    }
  }
 
  // Function for Navigate to edit experiments
  navigateToEdit() {
    if (this.expType === "pho") {
      this.router.navigate([
        `/${CatExpertRouterLink.Studio}/${CatExpertRouterLink.Pho}/${CatExpertRouterLink.Experiments}/${this.expId}/${CatExpertRouterLink.Edit}`,
      ]);
    } else {
      this.router.navigate([
        `/${CatExpertRouterLink.Studio}/${CatExpertRouterLink.Assortment}/${CatExpertRouterLink.Experiments}/${this.expId}/${CatExpertRouterLink.Edit}`,
      ]);
    }
  }
 
  navigateToStep(step: any) {
    this.selectedStep = step;
    if (this.expType === "pho") {
      // For expand ans collaspe sub menu
      if (step.title === "level2") {
        step.sub_menu_show = !step.sub_menu_show;
        return;
      }
      if (step == "start-voting") {
        step = "voting";
        this.router.navigate([
          `/${CatExpertRouterLink.Studio}/${step?.module}/${CatExpertRouterLink.Experiments}/${this.expId}/${step?.slug}`,
        ]);
        return;
      }
      if (step == "user-voting") {
        step = "voting";
        this.router.navigate([
          `/${CatExpertRouterLink.Studio}/${step?.module}/${CatExpertRouterLink.Experiments}/${this.expId}/${step?.slug}/users`,
        ]);
        return;
      }
      this.router.navigate([
        `/${CatExpertRouterLink.Studio}/${step?.module}/${CatExpertRouterLink.Experiments}/${this.expId}/${step?.slug}`,
      ]);
    } else {
      if (step.slug === CatExpertRouterLink.PlanogramDashboard) {
        let queryParams = this.routerParams?.skip_level;
        if (
          [
            "use_without_store_data_for_space",
            "use_store_data_for_space",
          ].includes(this.clusteringMode)
        ) {
          queryParams =
            this.clusteringMode === "use_without_store_data_for_space"
              ? "eda"
              : this.clusteringMode === "use_store_data_for_space"
                ? "clustering"
                : this.routerParams?.skip_level;
        }
 
        this.router.navigate(
          [
            `/${CatExpertRouterLink.Studio}/${step?.module}/${CatExpertRouterLink.Experiments}/${this.expId}/${step?.slug}`,
          ],
          { queryParams: { skip_level: queryParams } }
        );
      } else {
        this.router.navigate([
          `/${CatExpertRouterLink.Studio}/${step?.module}/${CatExpertRouterLink.Experiments}/${this.expId}/${step?.slug}`,
        ]);
      }
    }
  }
 
  getSidebarStatesCall() {
    this.stateService.getSidebarStatesByExpId().subscribe((response: any) => {
      this.sidebarStates = response.states;
      // this.stateService.setResponseData(response.states);
      if (this.domainType === "pho") {
        window.localStorage.sideBarState = JSON.stringify(response.states);
        response.states.forEach((state: any) => {
          if (state.title === "simulation" && state.status === "COMPLETED") {
            let financialImpactState = response.states.find(
              (item: any) => item.title === "financial-impact"
            );
            if (financialImpactState) {
              const withFinancialImpact =
                financialImpactState.status === "COMPLETED";
              sessionStorage.setItem(
                "withFinancialImpact",
                String(withFinancialImpact)
              );
            } else {
              sessionStorage.setItem("withFinancialImpact", "false");
            }
          }
        });
      }
      if(this.domainType === 'pho') {
        this.phoMappingSidebarStatus();
      } else {
        this.mappingSidebarStatus();
      }
    });
  }
 
  mappingSidebarStatus() {
    // Map over main steps
    this.steps.forEach((step: any) => {
      // Check condition for setting 'pending' status
      if (
        step.order === 5 &&
        [
          "use_without_store_data",
          "use_existing_clusters",
          "use_without_store_data_for_space",
        ].includes(this.clusteringMode)
      ) {
        step.status = "pending";
      } else {
        // Find corresponding status from sidebarStates
        const matchedItem = this.sidebarStates.find(
          (item: any) => step.title === item.title
        );
        if (step.title === "level2") {
          // For once sku funnel and nesting status is completed, then only level 2 menu is active
          step["status"] =
            this.checkAnyCompleted() ||
            this.level === CatExpertRouterLink.SkuFunnel
              ? "completed"
              : "pending";
 
          step?.sub_level.map((subItem: any) => {
            if (subItem.title === matchedItem.title) {
              subItem["status"] = matchedItem.status.toLowerCase();
            }
          });
        } else {
          if (matchedItem) {
            step.status = matchedItem.status.toLowerCase();
          }
        }
      }
    });
 
    // this.steps.map((step: any) => {
    //   this.sidebarStates.map((item: any) => {
    //     if (
    //       step.order === 5 &&
    //       (this.clusteringMode === "use_without_store_data" ||
    //         this.clusteringMode === "use_existing_clusters")
    //     ) {
    //       step["status"] = "pending";
    //     } else if (step.title === "level2") {
    //       // For once sku funnel and nesting status is completed, then only level 2 menu is active
    //       step["status"] =
    //         this.checkAnyCompleted() ||
    //         this.level === CatExpertRouterLink.SkuFunnel
    //           ? "completed"
    //           : "pending";
 
    //       step?.sub_level.map((subItem: any) => {
    //         if (subItem.title === item.title) {
    //           subItem["status"] = item.status.toLowerCase();
    //         }
    //       });
    //     } else {
    //       if (step.title === item.title) {
    //         step["status"] = item.status.toLowerCase();
    //       }
    //     }
    //   });
    // });
 
    // Check conditions for spaceSteps
    if (
      ["use_without_store_data_for_space", "use_store_data_for_space"].includes(
        this.clusteringMode
      )
    ) {
      // Set 'completed' status for spaceSteps
      this.spaceSteps.forEach((spaceStep: any) => {
        spaceStep.status = "completed";
      });
    } else {
      // Find simulation status and set status for spaceSteps accordingly
      const simulationStatus = this.sidebarStates.find(
        (item: any) => item.title === "simulation"
      );
      if (simulationStatus) {
        this.spaceSteps.forEach((spaceStep: any) => {
          spaceStep.status = simulationStatus.status.toLowerCase();
        });
      }
    }
 
    // Map over phoSecondarySteps and set status based on sidebarStates
    this.phoSecondarySteps.forEach((step: any) => {
      const matchedItem = this.sidebarStates.find(
        (item: any) => step.title === item.title
      );
      if (matchedItem) {
        step.status = matchedItem.status.toLowerCase();
      }
    });
    this.setAssortmentStepsLock();
    this.stateService.getStatesList(this.steps);
  }
 
  phoMappingSidebarStatus() {
    this.steps.map((step: any) => {
      this.sidebarStates.map((item: any) => {
        if (
          step.order === 5 &&
          (this.clusteringMode === "use_without_store_data" ||
            this.clusteringMode === "use_existing_clusters")
        ) {
          step["status"] = "pending";
        } else if (step.title === "level2") {
          // For once sku funnel and nesting status is completed, then only level 2 menu is active
          step["status"] =
            this.checkAnyCompleted() ||
            this.level === CatExpertRouterLink.SkuFunnel
              ? "completed"
              : "pending";
 
          step?.sub_level.map((subItem: any) => {
            if (subItem.title === item.title) {
              subItem["status"] = item.status.toLowerCase();
            }
          });
        } else {
          if (step.title === item.title) {
            step["status"] = item.status.toLowerCase();
          }
        }
        // Once the simulation status is completed, enable the space link
        if (item.title === "simulation") {
          this.spaceSteps.map((spaceStep: any) => {
            spaceStep["status"] = item.status.toLowerCase();
          });
        }
      });
    });
 
    this.phoSecondarySteps.forEach((step: any) => {
      this.sidebarStates.map((item: any) => {
        if (step.title === item.title) {
          step["status"] = item.status.toLowerCase();
        }
      });
    });
    this.setAssortmentStepsLock();
    this.stateService.getStatesList(this.steps);
  }
 
  setAssortmentStepsLock() {
    if (this.lockAdded) {
      return;
    }
    this.phoSecondarySteps.forEach((step: any) => {
      if (step.title === "voting") {
        if (step.status === "completed") {
          this.lockAssortmentSteps(true);
        }
        if (step.status === "in_progress") {
          this.getVotingSummary();
        }
      }
    });
  }
  lockAssortmentSteps(value: boolean) {
    let obj: any = {};
    obj[this.expId!] = value;
    sessionStorage.setItem("lockAssortment", JSON.stringify(obj));
    this.stateService.setAssortmentLock(obj);
    this.lockAdded = true;
  }
  getVotingSummary() {
    const apiUrl = this.stateService.apiList.experiments.votingSummary(
      this.expId
    );
 
    this.httpService.getMethod(apiUrl).subscribe({
      next: (res) => {
        if (res) {
          this.lockAssortmentSteps(true);
        }
      },
      error: (err) => {
        if (err && err.status === 404) {
          this.lockAssortmentSteps(false);
        }
      },
    });
  }
  // Helper function check funnel and substitution is completed
  checkAnyCompleted() {
    const titlesToCheck = ["funnel", "substitution"];
    return this.sidebarStates.some(
      (state: any) =>
        titlesToCheck.includes(state.title) && state.status === "COMPLETED"
    );
  }
 
  checkSidebarActive() {
    this.sidebarCollapse = !this.sidebarCollapse;
    this.actionCollapseSidebar.emit(this.sidebarCollapse);
  }
 
  showMenuItems(updatedVariablekey: string = "") {
    if (updatedVariablekey === "planDetails") {
      this.planDetails.dropShow = true;
      this.spaceDetails.dropShow = false;
    } else if (updatedVariablekey === "spaceDetails") {
      this.spaceDetails.dropShow = true;
      this.planDetails.dropShow = false;
    } else if (updatedVariablekey === "phoDetails") {
      this.phoDetails.dropShow = true;
      this.planDetails.dropShow = false;
    } else {
      this.spaceDetails.dropShow = false;
      this.phoDetails.dropShow = false;
      this.planDetails.dropShow = false;
    }
  }
 
  hideMenuItems(updatedVariablekey: string = "") {
    if (updatedVariablekey === "planDetails") {
      this.planDetails.dropShow = false;
      this.spaceDetails.dropShow = false;
    } else if (updatedVariablekey === "spaceDetails") {
      this.spaceDetails.dropShow = false;
      this.planDetails.dropShow = false;
    } else if (updatedVariablekey === "phoDetails") {
      this.phoDetails.dropShow = false;
      this.planDetails.dropShow = false;
    } else {
      this.phoDetails.dropShow = false;
      this.spaceDetails.dropShow = false;
      this.planDetails.dropShow = false;
    }
  }
  isSpaceSidebarEnable() {
    return (
      (this.domainType === "catexpert" || this.domainType === "space") &&
      (this.expType === "space_assortment" ||
        this.expType === "space-assortment")
    );
  }
 
  ngOnDestroy() {
    if (this.expType !== "pho") {
      this.statesSubscription.unsubscribe();
    }
  }
}