export enum CatExpertRouterLink {
  Login = "login",

  Home = "home",

  Studio = "studio",

  Create = "create",

  Assortment = "assortment",

  SpaceAssortment = "space-assortment",

  ClusteringInsights = "clustering-insights",

  Clustering = "clustering",

  DataExploration = "data-exploration",

  Pho = "pho",

  Edit = "edit",

  Eda = "eda",

  Experiments = "experiments",

  Nesting = "nesting",

  LevelZero = "level-zero",

  Simulation = "simulation",

  Optimization = "optimization",

  Settings = "settings",

  SkuFunnel = "sku-funnel",

  ClusterPlanoOverview = "cluster-plano-overview",

  DynamicNesting = "dynamic-nesting",

  Voting = "voting",

  Vote = "vote",

  VotingSummary = "summary",

  UsersVoting = "users",

  LogisticsFramework = "logistics-framework",

  ProjectDetails = "project-details",

  GeneratePublish = "generate-publish",

  PlanogramDashboard = "plano-dashboard",

  Planogram = "planogram",

  Opr = "ppr",

  OprView = "ppr-view",

  ExecutuveSummary = "executive-summary",

  Dashboard = 'global-dashboard',

  Consolidation = 'global-consolidation',
  
  OprSummary = "ppr-summary"
}
