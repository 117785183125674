import { Component } from '@angular/core';

@Component({
  selector: 'app-read-only-block',
  templateUrl: './read-only-block.component.html',
  styleUrl: './read-only-block.component.scss'
})
export class ReadOnlyBlockComponent {

}
