export const FinImapct = {
  SummaryTabTableLabels: [
    "Total SKUs",
    "Keep",
    "Consult",
    "Delist",
    "SKUs Delisted (%)",
    "Total Volume Delisted (%)",
    "Total Revenue Delisted (%)",
    "MACO Delisted (%)",
    "SCL Delisted (%)",
  ],
};
export const SKUFeatures = [
  {
    label: "Total Volume",
    unit: "(HL)",
    fixed: true,
    key: "total_volume",
    formatNumber: true,
  },
  {
    label: "MACO/HL SKU",
    unit: "($)",
    key: "maco_hl",
    formatNumber: true,
  },
  {
    label: "MACO Margin SKU",
    unit: "(HL)",
    key: "maco_margin",
    showPercentage: true,
  },
  {
    label: "VILC/HL SKU",
    unit: "($)",
    key: "vilc_hl",
  },
  {
    label: "MACO Uplift",
    unit: "",
    fixed: true,
    formatNumber: true,
    key: "maco_uplift",
  },
  {
    label: "Total MACO",
    unit: "($)",
    fixed: true,
    key: "total_maco",
    formatNumber: true,
  },
  {
    label: "MACO/HL Segment",
    unit: "($)",
    key: "maco_hl_segment",
  },
  {
    label: "MACO Margin Segment",
    key: "maco_margin_segment",
    showPercentage: true,
  },
  {
    label: "VILC/HL Segment",
    unit: "($)",
    key: "vilc_hl_segment",
  },
  {
    label: "Volume Retained",
    unit: "(HL)",
    fixed: true,
    key: "volume_retained",
    formatNumber: true,
  },
  {
    label: "Total SCL",
    unit: "($)",
    key: "scl",
  },
  {
    label: "MACO/HL Country",
    unit: "($)",
    key: "maco_hl_country",
  },
  {
    label: "MACO Margin Country",
    key: "maco_margin_country",
    showPercentage: true,
  },
  {
    label: "VILC/HL Country",
    unit: "($)",
    key: "vilc_hl_country",
  },
  {
    label: "Volume Growth",
    key: "growth",
    showPercentage: true,
  },
];

export const TreeData =[
  {
    label: "MACO Driven",
    selectable: false, // Parent node not selectable
    expanded: true, // Control expand/collapse state
    children: [
      { label: "MACO Improvement: VILC", data: "opt1", selectable: true },
      { label: "MACO Improvement: PINC", data: "opt2", selectable: true },
      { label: "Negative MACO Uplift", data: "opt3", selectable: true },
    ],
  },
  {
    label: "Innovations / Marketing",
    selectable: false, // Parent node not selectable
    expanded: true, // Control expand/collapse state
    children: [
      { label: "Long Term Bet", data: "opt1", selectable: true },
      { label: "Seed <1 year age", data: "opt2", selectable: true },
      { label: "Contractual Restriction", data: "opt3", selectable: true },
      { label: "Unique SKU", data: "opt4", selectable: true },
    ],
  },
  {
    label: "Volume Improvement",
    selectable: false, // Parent node not selectable
    expanded: true, // Control expand/collapse state
    children: [
      {
        label: "Service Level Impacted/ Unavailability",
        data: "opt1",
        selectable: true,
      },
      { label: "Distribution Expansion", data: "opt2", selectable: true },
      {
        label: "Price Tier/Segment re-position",
        data: "opt3",
        selectable: true,
      },
      {
        label: "Transition to MTO(Improve SCL)",
        data: "opt4",
        selectable: true,
      },
    ],
  },
  {
    label: "Others",
    selectable: true, // 'Others' node is selectable
  },
];


export const _6to12MonthsTableData = [
  {
    bc_thersold: "<=1 Threshold missed VS BC",
    greater_one: "",
    less_than_one: "",
    highlight_keep: ["less_than_one"],
    highlight_consult: ["greater_one"],
  },
  {
    bc_thersold: ">1 Threshold missed VS BC",
    greater_one: "",
    less_than_one: "",
    highlight_keep: ["less_than_one"],
    highlight_consult: ["greater_one"],
  },
];

export const _12to15MonthsTableData = [
  {
    bc_thersold: "<=1 Threshold missed VS BC",
    volume: "Volume > 80% of BC",
    gre_thersold: { decline: 0, grow: 0 },
    less_thersold: { decline: 0, grow: 0 },
    highlight_keep: [
      "gre_thersold.grow",
      "less_thersold.decline",
      "less_thersold.grow",
    ],
    highlight_consult: ["gre_thersold.decline"],
  },
  {
    bc_thersold: "<=1 Threshold missed VS BC",
    volume: "Volume < 80% of BC",
    gre_thersold: { decline: 0, grow: 0 },
    less_thersold: { decline: 0, grow: 1 },
    highlight_keep: ["less_thersold.grow"],
    highlight_consult: [
      "gre_thersold.decline",
      "gre_thersold.grow",
      "less_thersold.decline",
    ],
  },
  {
    bc_thersold: ">1 Threshold missed VS BC",
    volume: "Volume > 80% of BC",
    gre_thersold: { decline: 0, grow: 5 },
    less_thersold: { decline: 0, grow: 0 },
    highlight_keep: ["less_thersold.decline", "less_thersold.grow"],
    highlight_consult: ["gre_thersold.decline", "gre_thersold.grow"],
  },
  {
    bc_thersold: ">1 Threshold missed VS BC",
    volume: "Volume < 80% of BC",
    gre_thersold: { decline: 0, grow: 2 },
    less_thersold: { decline: 0, grow: 2 },
    highlight_consult: [
      "gre_thersold.decline",
      "gre_thersold.grow",
      "less_thersold.decline",
      "less_thersold.grow",
    ],
  },
];

export const _15to24MonthsTableData = [
  {
    country_volume: "Volume > 0.03% of Country",
    maco_margin1: { decline: 0, grow: 7 },
    maco_margin2: { decline: 1, grow: 5 },
    highlight_keep: ["maco_margin2.grow", "maco_margin2.decline"],
    highlight_consult: ["maco_margin1.decline", "maco_margin1.grow"],
  },
  {
    country_volume: "Volume < 0.03% of Country",
    maco_margin1: { decline: 0, grow: 7 },
    maco_margin2: { decline: 1, grow: 5 },
    highlight_delist: [
      "maco_margin1.grow",
      "maco_margin1.decline",
      "maco_margin2.decline",
    ],
    highlight_keep: ["maco_margin2.grow"],
  },
];

export const above24MonthsTableData = [
  {
    scl: "< 20% country SCL Pareto",
    yoy_volume: "20% - 100%",
    negative_maco: 0,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco"],
    highlight_keep: [
      "maco_margin1.grow",
      "maco_margin1.decline",
      "maco_margin2.grow",
      "maco_margin2.decline",
    ],
  },
  {
    scl: "< 20% country SCL Pareto",
    yoy_volume: "5% - 20%",
    negative_maco: 0,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco"],
    highlight_keep: ["maco_margin2.grow", "maco_margin2.decline"],
    highlight_consult: ["maco_margin1.grow", "maco_margin1.decline"],
  },
  {
    scl: "< 20% country SCL Pareto",
    yoy_volume: "0.5% - 5%",
    negative_maco: 0,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco"],
    highlight_keep: ["maco_margin2.grow", "maco_margin2.decline"],
    highlight_consult: ["maco_margin1.grow", "maco_margin1.decline"],
  },
  {
    scl: "< 20% country SCL Pareto",
    yoy_volume: "0%-0.5%",
    negative_maco: 1,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco", "maco_margin1.decline"],
    highlight_keep: ["maco_margin2.grow"],
    highlight_consult: ["maco_margin1.grow", "maco_margin2.decline"],
  },
  {
    scl: "> 20% country SCL Pareto",
    yoy_volume: "20% - 100%",
    negative_maco: 0,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco"],
    highlight_keep: ["maco_margin2.grow", "maco_margin2.decline"],
    highlight_consult: ["maco_margin1.grow", "maco_margin1.decline"],
  },
  {
    scl: "> 20% country SCL Pareto",
    yoy_volume: "5% - 20%",
    negative_maco: 0,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco"],
    highlight_keep: ["maco_margin2.grow"],
    highlight_consult: [
      "maco_margin1.grow",
      "maco_margin1.decline",
      "maco_margin2.decline",
    ],
  },
  {
    scl: "> 20% country SCL Pareto",
    yoy_volume: "0.5%-5%",
    negative_maco: 1,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: ["negative_maco", "maco_margin1.decline"],
    highlight_consult: [
      "maco_margin1.grow",
      "maco_margin2.decline",
      "maco_margin2.grow",
    ],
  },
  {
    scl: "> 20% country SCL Pareto",
    yoy_volume: "0%-0.5%",
    negative_maco: 47,
    maco_margin1: { decline: 0, grow: 0 },
    maco_margin2: { decline: 0, grow: 0 },
    highlight_delist: [
      "negative_maco",
      "maco_margin1.decline",
      "maco_margin1.grow",
      "maco_margin2.decline",
    ],
    highlight_consult: ["maco_margin2.grow"],
  },
];

export const _12to15MonthsTableSubCols = [
  {
    name: "",
    colspan: "",
    bgColor: "#fff",
  },
  {
    name: "",
    colspan: "",
    bgColor: "#fff",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
];

export const _15to24MonthsTableSubCols =[
  {
    name: "",
    colspan: "",
    bgColor: "#fff",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
];

export const above24MonthsTableSubCols =  [
  {
    name: "SCL",
    colspan: "",
    bgColor: "#fff",
  },
  {
    name: "Volume",
    colspan: "",
    bgColor: "#fff",
  },
  {
    name: "",
    colspan: "",
    bgColor: "#efefef",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Declining",
    colspan: "",
    bgColor: "",
  },
  {
    name: "Growing",
    colspan: "",
    bgColor: "",
  },
];


export const _6to12MonthsTableCols = [
  {
    label: "",
    value: "bc_thersold",
    valueType: "",
    sortable: false,
  },
  {
    label: ">1 Threshold missed VS Country Average",
    value: "greater_one",
    valueType: "",
    sortable: false,
  },
  {
    label: "<=1 Threshold missed VS Country Average",
    value: "less_than_one",
    valueType: "",
    sortable: false,
  },
];

export const _12to15MonthsTableCols = [
  {
    label: "",
    value: "volume",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
  {
    label: ">1 Threshold missed VS Country Average",
    value: "gre_thersold",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
  {
    label: "<=1 Threshold missed VS Country Average",
    value: "less_thersold",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
];

export const _15to24MonthsTableCols = [
  {
    label: "",
    value: "country_volume",
    valueType: "",
    sortable: false,
  },
  {
    label: "MACO Margin < Country Average",
    value: "maco_margin1",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
  {
    label: "MACO Margin > Country Average",
    value: "maco_margin2",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
];

export const above24MonthsTableCols = [
  {
    label: "Volume Trend YoY",
    value: "yoy_volume",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
  {
    label: "Negative MACO",
    value: "negative_maco",
    valueType: "",
    sortable: false,
  },
  {
    label: "MACO Margin < Country Average",
    value: "maco_margin1",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
  {
    label: "MACO Margin > Country Average",
    value: "maco_margin2",
    valueType: "",
    sortable: false,
    colspan: "2",
  },
];

