import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-edit-modify-button",
  templateUrl: "./edit-modify-button.component.html",
  styleUrls: ["./edit-modify-button.component.scss"],
})
export class EditModifyButtonComponent implements OnInit {
  @Output() actionButtonCall = new EventEmitter<any>();
  @Input() iconSize: string = "";
  @Input() disabled:any;

  constructor() {}

  ngOnInit(): void {
  }

  buttonClickEvent() {
    this.actionButtonCall.emit();
  }
}
