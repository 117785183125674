<p-table
  #phoTbl
  [lazy]="lazyLoad !== null ? true : false"
  (onLazyLoad)="onLazyLoad($event)"
  [value]="skeletonLoader ? skeletonData : tableData"
  responsiveLayout="scroll"
  [(selection)]="selectedItems"
  [dataKey]="uniqueKey ? uniqueKey : 'id'"
  styleClass="pho-table {{ styleClassName }}"
  [paginator]="showPagination"
  [rows]="rowFlag ? rowFlag : 5"
  [columns]="tableColumns"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} - {last} of {totalRecords} entries"
  [rowsPerPageOptions]="pagination.options"
  [virtualScroll]="virtualScrollFlag"
  [virtualScrollItemSize]="51"
  (onRowSelect)="checkRow($event)"
  (onRowUnselect)="checkRow($event)"
  appendTo="body"
  rowGroupMode="rowspan"
  [groupRowsBy]="groupRowsBy"
  paginatorDropdownAppendTo="body"
  [first]="paginationFirst"
  [showFirstLastIcon]="false"
  [selectionMode]="selectionMode"
  [scrollable]="scrollFlag"
  [scrollHeight]="scrollHeight"
  scrollDirection="both"
  [totalRecords]="totalRecords"
  (onPage)="onPage($event)"
>
  <ng-template pTemplate="header">
    <tr *ngIf="topHeader?.length">
      <ng-container *ngFor="let column of topHeader">
        <th [attr.colspan]="column.colSpan" class="text-center top-header">
          <ng-container *ngIf="column"> {{ column?.label }}</ng-container>
        </th>
      </ng-container>
    </tr>
    <tr>
      <th *ngIf="isSelectableTable" style="width: 4rem">
        <p-tableHeaderCheckbox (click)="checkRow($event)"></p-tableHeaderCheckbox>
      </th>
      <!-- dynamic column names -->
      <ng-container
        *ngFor="let column of tableColumns; let i = index; let lastIndex = last"
      >
        <th
          [attr.colspan]="column.colspan"
          [attr.rowspan]="column.rowspan"
          class="{{ column.value }}-th {{ dataFrom }}-th {{ textAlign }}"
          [pSortableColumn]="column.sortable ? column.value : ''"
          [pSortableColumnDisabled]="!column.sortable"
          pFrozenColumn
          [frozen]="column.frozenColumn ? column.frozenColumn : ''"
          [alignFrozen]="lastIndex ? 'right' : 'left'"
          [ngClass]="
            scrollFlag
              ? column?.frozenColumn
                ? 'frozen_col'
                : column?.colMinWidth
                  ? 'col-minwidth'
                  : 'non_frozen_col'
              : column?.colMinWidth
                ? 'col-minwidth'
                : 'non_frozen_col'
          "
        >
          <span class="flex items-center justify-start"
            ><span
              >{{ column.label }}
              <span
                class="text-xs text-gray-600 font-normal"
                *ngIf="column?.subHeading"
                >({{ column?.subHeading }})</span
              >
            </span>
            <p-sortIcon *ngIf="column.sortable" [field]="column.value">
            </p-sortIcon>
            <div
              *ngIf="column.infoIconTooltip"
              class="flex items-center"
              [pTooltip]="column.infoIconTooltip"
              tooltipPosition="bottom"
            >
              <app-svg-icon
                class="flex items-center ml-1"
                icon="info"
                color="#666"
                padding="5px"
              ></app-svg-icon>
            </div>
          </span>
          <ng-container *ngIf="column?.inputType === 'checkbox'">
            <p-tableHeaderCheckbox
              class="simu-checkbox"
              (click)="onCheckedOrUpdated()"
            ></p-tableHeaderCheckbox>
          </ng-container>
        </th>
      </ng-container>
    </tr>
    <tr class="filters-row" *ngIf="showFilters">
      <th *ngIf="isSelectableTable"></th>
      <ng-container
        *ngFor="let column of tableColumns; let i = index; let lastIndex = last"
      >
        <th
          pFrozenColumn
          [frozen]="column.frozenColumn ? column.frozenColumn : ''"
          [alignFrozen]="
            column?.alignFrozen
              ? column?.alignFrozen
              : lastIndex
                ? 'right'
                : 'left'
          "
          [ngClass]="
            scrollFlag
              ? column?.frozenColumn
                ? 'frozen_col'
                : column?.colMinWidth
                  ? 'col-minwidth'
                  : 'non_frozen_col'
              : column?.colMinWidth
                ? 'col-minwidth'
                : ''
          "
          *ngIf="column?.value !== 'actions'"
        >
          <p-columnFilter
            [matchMode]="
              column?.filterType === 'input_text' && column?.filterMatchMode
                ? 'contains'
                : 'equals'
            "
            [showMenu]="false"
            [showClearButton]="false"
            [field]="column.value"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <input
                *ngIf="column?.filterType == 'input_text'"
                class="filter-input"
                (keyup)="filter($any($event.target).value)"
                placeholder="Type here"
                type="text"
                pInputText
              />
              <p-autoComplete
                [suggestions]="filterSuggestions"
                (completeMethod)="
                  findFilterOptions($event, column?.filterOptions)
                "
                (onSelect)="filterTable($event.value, column)"
                (onClear)="clearAutocompleteFilters()"
                styleClass="filter-autocomplete"
                *ngIf="column?.filterType == 'dropdown'"
                placeholder="Type & select"
              />
            </ng-template>
          </p-columnFilter>
        </th>
      </ng-container>
      <th *ngIf="tableActions.length > 0"></th>
    </tr>
    <!-- sub heading columns -->
    <tr class="table-sub-header" *ngIf="subHeaderColumns.length > 0">
      <th
        [attr.colspan]="subCol.colspan"
        *ngFor="let subCol of subHeaderColumns"
        [style.background-color]="subCol.bgColor"
        [ngClass]="textAlign"
        pFrozenColumn
        [frozen]="subCol.frozenColumn ? subCol.frozenColumn : ''"
        [alignFrozen]="subCol?.alignFrozen"
      >
        {{ subCol.name }}
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-data
    let-rowIndex="rowIndex"
    let-rowgroup="rowgroup"
    let-rowspan="rowspan"
    let-even="even"
    let-odd="odd"
    let-itemRow
  >
    <ng-template [ngIf]="skeletonLoader">
      <tr>
        <td *ngFor="let column of tableColumns">
          <p-skeleton height="1rem" styleClass="mb-2"></p-skeleton>
        </td>
      </tr>
    </ng-template>
    <ng-template [ngIf]="!skeletonLoader">
      <tr
        [pEditableRow]="itemRow"
        [ngClass]="{
          'selected-row': selectedRow == itemRow,
          'cursor-pointer': rowClick,
          'highlight-row':
            data?.index == '% of SKUs delisted' ||
            data?.index == '% of Total Volume Delisted' ||
            data?.index == '% of Total Revenue Delisted' ||
            data?.index == '% of Total MACO Delisted' ||
            data?.index == '% of Total SCL Delisted'
        }"
        [class]="
          even &&
          (dataFrom == 'opr' ||
            dataFrom == 'user-voting' ||
            dataFrom == 'exe-summary-sku-details')
            ? 'even-row'
            : dataFrom == 'opr' ||
                dataFrom == 'user-voting' ||
                dataFrom == 'exe-summary-sku-details'
              ? 'odd-row'
              : ''
        "
        (click)="toSelectedRow($event, itemRow)"
        (mouseenter)="enableEditing($event, itemRow)"
        (mouseleave)="disableEdititng($event, itemRow)"
        [pSelectableRow]="data"
      >
        <!-- dynamic table data -->
        <!-- grouby column -->
        <td *ngIf="rowgroup && groupRowsBy" [attr.rowspan]="rowspan">
          <img
            [alt]="data[groupRowsBy]"
            width="32"
            style="vertical-align: middle"
          />
          <span class="font-bold ml-2">{{ data[groupRowsBy] }}</span>
        </td>
        <!-- common columns -->
        <td *ngIf="isSelectableTable">
          <p-tableCheckbox
            [value]="data"
            (click)="$event.stopPropagation()"
          ></p-tableCheckbox>
        </td>
        <ng-container
          *ngFor="
            let column of tableColumns;
            let i = index;
            let lastIndex = last
          "
        >
          <ng-container
            *ngIf="
              column.value !== 'actions' &&
              column.value !== 'final_verdict' &&
              column.value != 'vote' &&
              column.value != 'apply'
            "
          >
            <ng-container
              *ngFor="let col of data[column.value] | keyvalue: originalOrder"
            >
              <td
                class="{{ dataFrom }}-td {{ column.value }}_td {{ textAlign }}"
              >
                <span
                  *ngIf="(data[column.value] | keyvalue)!.length > 0"
                  [ngClass]="
                    data.highlight_delist &&
                    data.highlight_delist.includes(column.value + '.' + col.key)
                      ? 'highlight-delist'
                      : data.highlight_keep &&
                          data.highlight_keep.includes(
                            column.value + '.' + col.key
                          )
                        ? 'highlight-keep'
                        : data.highlight_consult &&
                            data.highlight_consult.includes(
                              column.value + '.' + col.key
                            )
                          ? 'highlight-consult'
                          : ''
                  "
                >
                  {{
                    nParseFloat(data, data[column.value][getString(col.key)])
                  }}
                </span>
              </td>
            </ng-container>
            <td
              [pEditableColumn]="data[column.value]"
              pEditableColumnField="remarks"
              pFrozenColumn
              [frozen]="column.frozenColumn ? column.frozenColumn : ''"
              [alignFrozen]="lastIndex ? 'right' : 'left'"
              *ngIf="(data[column.value] | keyvalue)?.length == undefined"
              class="{{ dataFrom }}-td {{ column.value }}_td {{ textAlign }}"
              [ngClass]="
                scrollFlag
                  ? column.frozenColumn
                    ? 'frozen_data'
                    : 'non_frozen_col'
                  : ''
              "
              [ngStyle]="
                column?.hasColor
                  ? {
                      'background-color':
                        data[column.colorCodeKey] || 'transparent'
                    }
                  : { 'background-color': 'transparent' }
              "
            >
              <div *ngIf="column?.inputType">
                <ng-container *ngIf="column?.inputType === 'checkbox'">
                  <p-tableCheckbox
                    class="simu-checkbox"
                    (click)="onCheckedOrUpdated()"
                    [value]="data"
                  ></p-tableCheckbox>
                </ng-container>

                <app-select-dropdown
                  *ngIf="column?.inputType == 'dropdown'"
                  [dropdownOptions]="column.dropdownOptions"
                  additionalStyleClass="table-dropdown"
                  [selectedValue]="data[column.value]"
                  [isReadonlyMode]="lockAssortment"
                  [isDisabled]="
                    (data['model_output'] === 'Delist' &&
                      (dataFrom === 'simulation-parent-table' ||
                        dataFrom === 'text-left')) ||
                    data['sku_age'] == '<=6M' || accessType ==='read'
                  "
                  (actionBtnCallback)="onTableDropdownChange($event, data)"
                >
                </app-select-dropdown>
                <ng-container *ngIf="column?.inputType == 'text_field'">
                  <app-table-input
                    *ngIf="column?.inputType == 'text_field'"
                    [bindedValue]="data[column.value]"
                    [isInputDisabled]="lockAssortment"
                    (actionBtnCallback)="onInputBlur($event, data)"
                  >
                  </app-table-input>
                </ng-container>

                <div *ngIf="column?.inputType == 'actions'">
                  <ng-container *ngFor="let action of column?.actionList">
                    <button
                      *ngIf="!action.from"
                      class="btn {{ action.action }}_btn"
                      (click)="onTableAction(data, action)"
                    >
                      {{ action.label }}
                      <img
                        src="assets/images/{{ action.btnIcon }}.svg"
                        alt=""
                      />
                    </button>
                  </ng-container>
                </div>

                <div *ngIf="column?.inputType == 'dot-view'">
                  <div
                    (click)="
                      dropdown.toggle($event);
                      toggleOverlay(column, data);
                      $event.stopPropagation()
                    "
                  >
                    <span>
                      <img
                        class="table-icons cursor-pointer"
                        src="/assets/images/threedots-grey.svg"
                      />
                    </span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div
                [matTooltipDisabled]="!column?.showTooltip"
                [matTooltip]="data[column.value]"
                *ngIf="!column?.inputType"
                [class]="
                  column?.highLightData
                    ? 'capitalize ' + column[data[column.value].toLowerCase()]
                    : ''
                "
                [ngClass]="
                  data.highlight_delist &&
                  data.highlight_delist.includes(column.value)
                    ? 'highlight-delist'
                    : data.highlight_keep &&
                        data.highlight_keep.includes(column.value)
                      ? 'highlight-keep'
                      : data.highlight_consult &&
                          data.highlight_consult.includes(column.value)
                        ? 'highlight-consult'
                        : ''
                "
                [ngStyle]="{
                  color:
                    column.value == 'final_recommendation' &&
                    getColor(data[column.value]),
                }"
              >
                {{
                  column?.setValueList
                    ? column.setValueList[data[column.value]]?.label
                    : nParseFloat(data, data[column.value])
                }}<span *ngIf="column?.valueType && data[column.value]">{{
                  column?.valueType
                }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.value == 'final_verdict' || column.value == 'vote'"
          >
            <td
              [pEditableColumn]="data[column.value]"
              pEditableColumnField="remarks"
              pFrozenColumn
              [frozen]="column.frozenColumn ? column.frozenColumn : ''"
              [alignFrozen]="lastIndex ? 'right' : 'left'"
              *ngIf="(data[column.value] | keyvalue)?.length == undefined"
              class="{{ dataFrom }}-td {{ column.value }}_td text-center"
              [ngClass]="
                scrollFlag
                  ? column.frozenColumn
                    ? 'frozen_col'
                    : 'non_frozen_col'
                  : ''
              "
            >
              <ng-container *ngIf="dataFrom == 'opr'">
                <app-button-secondary
                  *ngIf="!data[column?.value]"
                  (actionButtonCall)="
                    onTableAction(data, { action: 'opr-decide' })
                  "
                  buttonCaption="Analyze"
                  btnMinWidth="106"
                  [isBtnDisabled]="isVotingClosed"
                ></app-button-secondary>
                <div
                  class="flex gap-1 justify-center"
                  *ngIf="data[column?.value] == 'Consult'"
                >
                  <app-svg-icon
                    class="consult-info"
                    color="#E5B611"
                    icon="consult-info"
                    padding="8px"
                  ></app-svg-icon>
                  <span class="consult-label">Consult</span>
                </div>
                <div
                  class="flex gap-1 justify-center"
                  *ngIf="data[column?.value] == 'Delist'"
                >
                  <app-svg-icon
                    class="red-cross"
                    color="#D14447"
                    icon="delist-cross"
                    padding="5px"
                  ></app-svg-icon>
                  <span class="delist-label">Delist</span>
                </div>
              </ng-container>
              <ng-container *ngIf="dataFrom == 'user-voting'">
                <div class="flex gap-3">
                  <app-delist-button
                    btnSize="small-btn"
                    *ngIf="!data[column?.value]"
                    [btnDisable]="isVotingClosed"
                    (actionBtnCallback)="
                      onTableAction(data, { action: 'delist-sku' })
                    "
                  ></app-delist-button>
                  <app-button-secondary
                    *ngIf="!data[column?.value]"
                    (actionButtonCall)="
                      onTableAction(data, { action: 'user-voting' })
                    "
                    buttonCaption="Analyze"
                    btnMinWidth="106"
                    [isBtnDisabled]="isVotingClosed"
                  ></app-button-secondary>
                </div>

                <div class="flex gap-2 justify-center">
                  <div
                    class="flex gap-1 justify-center"
                    *ngIf="data[column?.value] == 'Keep'"
                  >
                    <app-svg-icon
                      class="green-tick"
                      color="#6EBC14"
                      icon="green-tick"
                      padding="6px"
                    ></app-svg-icon>
                    <span class="keep-label">Keep</span>
                  </div>
                  <div
                    class="flex gap-1 justify-center"
                    *ngIf="data[column?.value] == 'Delist'"
                  >
                    <app-svg-icon
                      class="red-cross"
                      color="#D14447"
                      icon="delist-cross"
                      padding="5px"
                    ></app-svg-icon>
                    <span class="delist-label">Delist</span>
                  </div>
                  <app-svg-icon
                    *ngIf="
                      hoveredRow === itemRow &&
                      data[column?.value] &&
                      !isVotingClosed
                    "
                    icon="edit-icon"
                    color="#000"
                    padding="10px"
                  ></app-svg-icon>
                </div>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- dynamic table actions -->
        <td
          class="actions-td"
          *ngIf="tableActions.length > 0 && dataFrom != 'opr'"
        >
          <ng-container *ngIf="dataFrom != 'user-voting' && dataFrom != 'opr'">
            <ng-container *ngFor="let action of tableActions">
              <button
                [disabled]="selectedItems.length > 0 || accessType ==='read'"
                *ngIf="!action.from"
                class="btn {{ action.action }}_btn"
                (click)="onTableAction(data, action)"
                [matTooltip]="accessType === 'edit' ? action?.tooltip : null"
              >
                {{ action.label }}
                <img src="assets/images/{{ action.btnIcon }}.svg" alt=""/>
              </button>
              <img
                class="reject-approve-images"
                style="margin-right: 8px; cursor: pointer"
                *ngIf="action.from"
                src="assets/images/{{ action.btnIcon }}.svg"
                alt=""
                [title]="action.btnIcon"
                (click)="onTableAction(data, action)"
              />
            </ng-container>
          </ng-container>
          <ng-container *ngIf="dataFrom == 'user-voting'">
            <div class="flex justify-between gap-2">
              <app-keep-button
                btnSize="small-btn"
                [isRecommended]="false"
                [btnDisable]="selectedItems.length > 0"
                (actionBtnCallback)="
                  onTableAction(data, { action: 'keep-sku' })
                "
              ></app-keep-button>
              <app-delist-button
                btnSize="small-btn"
                [isRecommended]="false"
                [btnDisable]="selectedItems.length > 0"
                (actionBtnCallback)="
                  onTableAction(data, { action: 'delist-sku' })
                "
              ></app-delist-button>
            </div>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td
        style="text-align: center; padding: 20px 10px"
        [attr.colspan]="columns?.length"
      >
        {{ "PHO.NO_RECORDS_FOUND" | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft" let-state>
    {{ "PHO.ITEM_PER_PAGE" | translate }}
  </ng-template>
</p-table>

<p-overlayPanel styleClass="option-dropdown" #dropdown appendTo="body">
  <ng-template pTemplate>
    <div class="container dashboard-dropdown-wrapper">
      <ng-container *ngFor="let option of actionColumnList">
        <div
          class="dropdown-option py-1 cursor-pointer"
          (click)="planAction(option)"
          *ngIf="
            overlayDetails?.state.toLowerCase() != 'planogram'
              ? option.key == 'export'
                ? false
                : true
              : true
          "
        >
          {{ option.label }}
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-overlayPanel>
