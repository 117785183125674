import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-autocomplete-email-search',
  templateUrl: './autocomplete-email-search.component.html',
  styleUrl: './autocomplete-email-search.component.scss'
})
export class AutocompleteEmailSearchComponent  implements OnInit, OnChanges{
  selectedItem!: any;
  @Input() ctrlName: any;
  @Input() suggestions: any[] = [];
  @Input() isMultipleSelected: boolean = false;
  @Input() isCompleteOnFocus: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() onSearch = new EventEmitter<any>();
  @Output() onEmailChange = new EventEmitter<any>();

  ngOnInit(): void {
  
    if (!this.isMultipleSelected && (this.ctrlName || this.ctrlName == '')) {
      this.selectedItem = this.ctrlName;
     }

     if(this.isMultipleSelected && this.ctrlName ){
      this.selectedItem =this.ctrlName;
     }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes?.ctrlName?.currentValue || changes?.ctrlName?.currentValue == '') && !this.isMultipleSelected) {
      this.selectedItem = changes?.ctrlName?.currentValue;
    }

    if(this.isMultipleSelected && this.ctrlName ){
      this.selectedItem =this.ctrlName;
     }
  }

  search(event: any) {
    let query = event.query;
    setTimeout(() => {
      this.onSearch.emit(query);
    }, 1500);
  }

  onSelectItem(event: any) {
    this.onEmailChange.emit(
      this.isMultipleSelected ? this.selectedItem : this.selectedItem?.email
    );
  }
}
