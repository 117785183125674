<p-autoComplete
  [(ngModel)]="selectedItem"
  [suggestions]="suggestions"
  [multiple]="isMultipleSelected"
  [completeOnFocus]="isCompleteOnFocus"
  [disabled]="isDisabled"
  (completeMethod)="search($event)"
  (onSelect)="onSelectItem($event)"
  (onUnselect) ="onRemoveItem($event)"  
  field="email"
  appendTo="body"
  panelStyleClass="user-email-acomplete-panel"
  styleClass="user-email-acomplete"
  placeholder="Type & select email"
  (onFocus)="getData()"
>
</p-autoComplete>

<!-- Suggested emails section -->
<div *ngIf="showDropdown && dataFrom === 'ppr-sidebar'" class="suggestion-dropdown" #dropdown>
  <div class="suggested-header">Suggested :</div>
  <div *ngIf="storedUserList && storedUserList.length > 0; else noRecords">
    <ul>
      <li class="text-sm my-3 mx-0" *ngFor="let emails of storedUserList" (click)="onEmailClick(emails)">
        {{ emails }}
      </li>
    </ul>
  </div>
  <ng-template #noRecords>
    <div class="no-records">No records found</div>
  </ng-template>
</div>
