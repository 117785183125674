import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HttpService } from "../../../services/http.service";

@Component({
  selector: "app-autocomplete-email-input",
  templateUrl: "./autocomplete-email-input.component.html",
  styleUrl: "./autocomplete-email-input.component.scss",
})
export class AutocompleteEmailInputComponent implements OnInit, OnChanges {
  @ViewChild("dropdown") dropdown!: ElementRef; // Access to the dropdown
  selectedItem!: any;
  @Input() ctrlName: any;
  @Input() suggestions: any[] = [];
  @Input() isMultipleSelected: boolean = false;
  @Input() isCompleteOnFocus: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() dataFrom: any = "";
  @Output() onSearch = new EventEmitter<any>();
  @Output() onEmailChange = new EventEmitter<any>();
  showDropdown: boolean = false;
  storedUserList: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private eRef: ElementRef
  ) {}

  @HostListener("document:click", ["$event"])
  clickOutside(event: Event): void {
    if (
      !this.eRef.nativeElement.contains(event.target) && // Click outside component
      (!this.dropdown || !this.dropdown.nativeElement.contains(event.target)) // Click outside dropdown
    ) {
      this.showDropdown = false;
    }
  }

  ngOnInit(): void {
    if (!this.isMultipleSelected && (this.ctrlName || this.ctrlName == "")) {
      this.selectedItem = this.ctrlName;
    }

    if (this.isMultipleSelected && this.ctrlName) {
      this.selectedItem = this.ctrlName;
    }

    this.storedUserList = JSON.parse(
      sessionStorage.getItem("suggestedUserList") || "[]"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.ctrlName &&
      changes?.ctrlName?.currentValue &&
      !this.isMultipleSelected
    ) {
      this.selectedItem = changes?.ctrlName?.currentValue;
    }
  
    if (this.isMultipleSelected && this.ctrlName) {
      this.selectedItem = this.ctrlName || [];
    }
  }
  
  search(event: any): void {
    const query = event.query;
    setTimeout(() => {
      // Ensure only valid email suggestions are passed
      const filteredSuggestions = this.suggestions.filter(
        (item: any) => item?.email?.trim() !== ""
      );
      this.onSearch.emit(query);
    }, 1500);
  }
  
  onSelectItem(event: any): void {
    if (this.isMultipleSelected) {
      // Ensure the email is unique before adding it to selectedItem
      if (
        event?.email &&
        !this.selectedItem.some((item: any) => item.email === event.email)
      ) {
        this.selectedItem = [...this.selectedItem, event];
      }
    } else {
      this.selectedItem = event && event.email ? event : null;
    }
  
    // Emit the selected item or email
    this.onEmailChange.emit(
      this.isMultipleSelected
        ? this.selectedItem
        : this.selectedItem?.email || null
    );
  }
  

  getData() {
    this.showDropdown = true;
  }

  hideData() {
    this.showDropdown = false;
  }

  onEmailClick(email: string): void {
    const isDuplicate = this.selectedItem.some(
      (item: any) => item.email === email
    );
    if (!isDuplicate) {
      this.selectedItem = [...this.selectedItem, { email }];
      this.onEmailChange.emit(this.selectedItem);
    }
    this.showDropdown = false;
    this.cdr.detectChanges();
  }

  onRemoveItem(email: any): void {
    this.selectedItem = this.selectedItem.filter(
      (item: any) => item.email !== email.email
    );
    this.onEmailChange.emit(this.selectedItem);
  }
}
